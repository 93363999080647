import { LitElement, html, css } from 'lit';

class OrangeButton extends LitElement {
  static properties = {
    isDisplayRightArrow: { type: Boolean },
    disabled: { type: Boolean }
  }
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.isDisplayRightArrow = false;
    this.disabled = false;
  } 
  updated(changedProperties) {
    
  }
  static styles = css`
    button {
      background: #D76315;
      color: white;
      padding: 16px;
      border: none;
      border-radius: 82px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      box-shadow: 0px 4px 0px 0px #95440E;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-family: "Noto Sans JP";
      &:disabled {
        border: 2px solid #A8ABA6;
        color: #A8ABA6;
        background-color: #eee;
        box-shadow: none;
      }
    }
  `
  render() {
    return html`
      <button .disabled="${this.disabled}">
        <slot></slot>
        ${this.isDisplayRightArrow ? html`
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3185 8.69998H1V7.30002H12.3185L7.00208 1.98357L8 1L15 8L8 15L7.00208 14.0164L12.3185 8.69998Z" fill="white"/>
          </svg>
        ` : ''}
      </button>
    `;
  }
}
if (!customElements.get('orange-button'))
  customElements.define('orange-button', OrangeButton);